// Object for global variables
var Vars = Vars || {};

// Default ease
TweenLite.defaultEase = Expo.easeOut;

// Global DOM variables which doesn't fit in `Vars`
var $document = $(document),
  $window = $(window),
  $html = $('html'),
  $body = $('body'),
  $htmlBody = $html.add($body);

/**
 * Global init function
 * @param  {string} parent    segment_1 of the URL
 * @param  {string} child     segment_2 of the URL
 */
App = function(parent, child) {
  'use strict';

  // Create new instance if function called withour `new`
  if (!(this instanceof App)) return new App(parent, child);
  var _ = this;

  var $sticky = $('.sticky');

  Vars.parent = parent.length ? parent : '';
  Vars.child = child;

  /* ================================
   * Dev stuff, to remove on prod
   * ================================ */

  // Prevent scrollTop on click on a[href="#"] links
  $('a[href="#"]').on('click', function(e) {
    e.preventDefault();
  });

  // Animate on scroll
  AOS.init({
    once: true,
    offset: 200,
    duration: 800,
  });

  $(window).scroll(function(event) {
    var scroll = $(window).scrollTop();

    if (scroll > $('.main-header').height()) {
      $sticky.addClass('sticky--open');
    } else {
      $sticky.removeClass('sticky--open');
    }
  });

  /* ================================
   * ./Dev stuff, to remove on prod
   * ================================ */

  /* ================================
   * Init global
   * ================================ */

  // Smooth scroll for anchors link
  $('a[href*="#"]:not([href="#"])').on('click', Utils.smoothScroll);

  // Cookies
  // var $cookies = $('#js-cookies');

  // if (Cookies.get('terms') !== 'accepted') {
  //  $cookies.addClass('is-visible');

  //  $('#js-cookies-btn').on('click', function() {
  //    TweenLite.to($cookies, 0.6, { className: '-=is-visible', onComplete: function() {
  //      $cookies.remove();
  //    } });
  //    // Set a cookie that expires in 1 year
  //    Cookies.set('terms', 'accepted', { expires : 365 });
  //    return false;
  //  });
  // }

  // Vars.bLazy = new Blazy(bLazyOptions);

  $window.on({
    load: function() {
      // window load
    },
    // Create throttled events
    resize: Utils.addEndEvent,
    scroll: Utils.addEndEvent,
  });

  /* ================================
   * ./Init global
   * ================================ */

  $('.burger-menu').on('click', function(event) {
    event.preventDefault();

    $body.toggleClass('open-menu');
  });

  if ($('#player').length) {
    var $player = $('#player');

    $('.thumb-video-play').on('click', function(event) {
      $(this)
        .next('#player')
        .attr('src', $player.data('src'));
      $('.thumb-video-play').addClass('invisible');
    });
  }

  /* ================================
   * Init pages
   * ================================ */

  Utils.hasMethod(Vars.parent, Vars.child);

  /* ================================
   * ./Init page
   * ================================ */

  return _;
};
